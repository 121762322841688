<template>
    <div>
       
        <v-row>
            <v-col class="py-2">
                <textFieldForm 
                    @valueChange="(v) => {values.name = v; delete serverErrors.name; delete serverErrors.slug}" 
                    :valueInput="values.name" 
                    :rule="[...rules.required]" 
                    label="Nombre"
                    :errorMessages="serverErrors.name||serverErrors.slug"
                    data-cy="name-input"
                ></textFieldForm>
            </v-col>
        </v-row>


    </div>
</template>

<script>

/* eslint-disable */
export default {
    props: ['values', 'serverErrors', 'edit'],
   
    data(){
        return {
            rules: {
				required: [
					v => !!v || 'El campo requerido'
				],
               
				
            },

            

          
        }
    },
   
    mounted(){
    },
    methods: {
        
       
    }
}
</script>

<style>

</style>